import React from 'react';
import { ErrorMessage, Localize } from '@planity/components';
import { Button } from '@planity/ui';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import styles from './confirmation.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useHistory } from 'react-router-dom';

export const Confirmation = props => {
	const {
		isPending,
		confirm,
		wasBooked,
		isLoading,
		isOnline,
		moveAppointment,
		appointment,
		userId,
		isGiftVoucher,
		isPrePayment,
		completedSteps,
		totalPrice,
		isDeposit,
		depositPrice,
		hasSameAmount,
		isDisabled,
		isVisiblePayment,
		payWithGiftVoucher,
		payWithCure,
		cureUsedForAppointment,
		giftVoucherPaymentInfo
	} = props;

	useStyles(styles);
	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	const history = useHistory();

	if (wasBooked) {
		return (
			<div css={styles.confirmation}>
				<Localize text={'bookAppointment.successMessage'} />
			</div>
		);
	} else {
		let isFromSignup = false;
		if (!isGiftVoucher) {
			isFromSignup = appointment.lastSignUpId === userId && !isPending;
		}
		const computeLabel = () => {
			if (
				(cureUsedForAppointment || giftVoucherPaymentInfo?.isValidated) &&
				moveAppointment
			) {
				return t('bookAppointment.book');
			}
			if (isGiftVoucher) {
				return `${t('onlinePayment.pay')} ${totalPrice}`;
			}
			if (payWithGiftVoucher || payWithCure) {
				return t('onlinePayment.reserve');
			}
			if (isOnline) {
				if (
					(!moveAppointment || (moveAppointment && !hasSameAmount)) &&
					isPrePayment
				) {
					return `${t('onlinePayment.pay')} ${
						isDeposit ? depositPrice : totalPrice
					}`;
				}
				return t('bookAppointment.book');
			}
			if (isPending) {
				return t('bookAppointment.booking');
			}
			return moveAppointment
				? t('bookAppointment.move')
				: t('bookAppointment.book');
		};

		return (
			<div>
				{isFromSignup && (
					<ErrorMessage
						error={'bookAppointment.notYetConfirmedMessage'}
						errorType={'warning'}
					/>
				)}
				<div className={styles.container}>
					<Button
						className={'large'}
						iconLeft={
							isVisiblePayment && !payWithGiftVoucher && !payWithCure && 'Lock'
						}
						id={'confirm-payment'}
						isDisabled={isDisabled}
						isFullMobile
						isFullWidth
						isLoading={isLoading}
						label={computeLabel()}
						onClick={confirm}
					/>
					{moveAppointment && (
						<Button
							className={styles.deleteLink}
							label={t('bookAppointment.cancelAppointmentChange')}
							type={'linked'}
							onClick={() => {
								history.push(routes.myAccount);
							}}
						/>
					)}
				</div>
			</div>
		);
	}
};
