import React, { useEffect, useState } from 'react';
import { Localize, ClickAndCollect } from '@planity/components';
import { AppointmentPreview } from './appointment_preview';
import { HowToModal, useClickAndCollect } from '@planity/components';
import { withFormFactor } from '../provider';
import { Card } from '@planity/ui';
import styles from './appointment_confirmation.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { invokeLambda } from '@planity/helpers/browser';
import { firebase } from '@planity/datastores';

export const AppointmentConfirmation = withFormFactor(
	({
		appointmentId,
		appointment,
		linkToBusiness,
		linkToBookAppointment,
		appointmentMoved,
		linkToClickAndCollect,
		hasFutureAppointments,
		isDark
	}) => {
		useStyles(styles);
		const business = appointment?.business;
		const [howToIsVisible, setHowToIsVisible] = useState(false);

		const [clickProducts, displayClickAndCollect, displayClickAndShop] =
			useClickAndCollect(
				!!business && business,
				!!appointment && appointment.businessId
			);
		const [isClickAndCollect, setIsClickAndCollect] = useState(
			displayClickAndCollect && !displayClickAndShop
		);
		const [isClickAndShop, setIsClickAndShop] = useState(displayClickAndShop);

		useEffect(() => {
			if (
				(displayClickAndCollect && !displayClickAndShop) !== isClickAndCollect
			)
				setIsClickAndCollect(displayClickAndCollect && !displayClickAndShop);
			if (displayClickAndShop !== isClickAndShop)
				setIsClickAndShop(displayClickAndShop);
		}, [displayClickAndCollect, displayClickAndShop]);

		const businessName = business ? business.name : '';
		const bookingMethods = appointment?.bm ?? {};
		const disableOnlinePaymentInfo = Object.keys(bookingMethods).length > 0;
		if (!appointment || !appointment.businessId) return null;
		return (
			<div
				className={`${styles.appointmentConf} ${isDark ? styles.isDark : ''}`}
			>
				<Card
					hasTitleInside
					id={
						appointmentMoved
							? 'last-appointment-moved-confirmation'
							: 'last-appointment-confirmation'
					}
					isVisibleMobile={false}
					title={
						<Localize
							text={
								appointmentMoved
									? 'myAccount.lastAppointmentMovedConfirmation'
									: 'myAccount.lastAppointmentConfirmation'
							}
						/>
					}
				>
					<AppointmentPreview
						appointment={appointment}
						appointmentId={appointmentId}
						bookingMethods={bookingMethods}
						collapseAppointmentSteps
						disableOnlinePaymentInfo={disableOnlinePaymentInfo}
						linkToBookAppointment={linkToBookAppointment}
						linkToBusiness={linkToBusiness}
					/>
				</Card>
				{(displayClickAndCollect || displayClickAndShop) && (
					<div
						className={`
						${styles.clickAndCollectContainer}
						${hasFutureAppointments && styles.border}
					`}
					>
						<div className={styles.clickAndCollectTopContainer}>
							<h2
								className={[styles.clickAndCollectTitle]}
								id='click-and-co-business-name'
							>
								{displayClickAndCollect && displayClickAndShop && (
									<Localize
										args={{ businessName }}
										text='clickAndCollect.clickAndShopAndCollect.businessSuggestsShop'
									/>
								)}
								{displayClickAndCollect && !displayClickAndShop && (
									<Localize
										args={{ businessName }}
										text='clickAndCollect.clickAndCollect.businessSuggestsShop'
									/>
								)}
								{!displayClickAndCollect && displayClickAndShop && (
									<Localize
										args={{ businessName }}
										text='clickAndCollect.clickAndShop.businessSuggestsShop'
									/>
								)}
							</h2>
							<span
								className={styles.howTo}
								id='click-and-co-howTo'
								onClick={() => setHowToIsVisible(true)}
							>
								<Localize text='clickAndCollect.howTo' />
							</span>
						</div>
						<ClickAndCollect
							business={business}
							businessId={appointment.businessId}
							clickAndCollectProducts={clickProducts.clickAndCollectProducts}
							clickAndShopProducts={clickProducts.clickAndShopProducts}
							displayClickAndCollect={displayClickAndCollect}
							displayClickAndShop={displayClickAndShop}
							isAppointmentTitle
							isDark={isDark}
							onSubmit={({
								isClickAndCollect,
								isClickAndShop,
								shippingExplanationSentence
							}) => {
								linkToClickAndCollect({
									business,
									isClickAndCollect,
									isClickAndShop,
									shippingExplanationSentence
								});
							}}
						/>
						<HowToModal
							business={business}
							displayClickAndCollect={displayClickAndCollect}
							displayClickAndShop={displayClickAndShop}
							displayOpeningHours={false}
							howToIsVisible={howToIsVisible}
							onClose={() => setHowToIsVisible(false)}
						/>
					</div>
				)}
			</div>
		);
	}
);
