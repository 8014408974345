// @ts-check
import React from 'react';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './cure_payment_choice.modules.scss';
import { useTranslation } from '@planity/localization';
import { Radio } from '@planity/ui';
import { noop, PAYMENT_METHOD } from '@planity/helpers';
import { Icon } from '@planity/ui';
import credentials from '@planity/credentials';

const { USE_CURE_FOR_PAYMENTS_ENABLED } = credentials;

export const CurePaymentChoice = ({
	paymentMethod,
	setPaymentMethod,
	eligibleCures,
	allServicesAreCoveredByCures,
	isLoading
}) => {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		curePaymentChoice: true
	});

	const shouldDisplayCureVoucherOption =
		USE_CURE_FOR_PAYMENTS_ENABLED && eligibleCures.length > 0;
	const hasMultipleEligibleCures = eligibleCures.length > 1;

	return (
		<div className={classes}>
			<div className={styles.choices}>
				{shouldDisplayCureVoucherOption && (
					<Radio
						id={'appointment-cure-payment-radio'}
						isChecked={paymentMethod === PAYMENT_METHOD.USE_CURE}
						name={'cure-voucher'}
						text={() => (
							<div className={styles.inlineContainer}>
								<Icon
									className={styles.inlineContainer__icon}
									icon='CurePayment'
									size={'medium'}
								/>
								<span>
									<>
										<span className={styles.curePaymentLabel}>
											{t('myAccount.cures.paymentLabel')} {'-'}{' '}
										</span>
										{t(`onlinePayment.how.cureVoucher.text`, {
											count: Number(hasMultipleEligibleCures)
										})}
									</>
									{eligibleCures.map(cure => (
										<div className={styles.cureItem} key={cure.id}>
											<span className={styles.cureName}>{cure.cureName}</span>
											<span className={styles.remainingQuantityTitle}>
												{'-'}{' '}
												<Localize text='myAccount.cures.remainingQuantity.title' />
											</span>
											<span className={styles.remainingQuantityLabel}>
												<Localize
													args={{
														originalQuantity: cure.originalQuantity,
														quantity: cure.quantity
													}}
													text={'myAccount.cures.remainingQuantity.label'}
												/>
											</span>
										</div>
									))}
									{!allServicesAreCoveredByCures && (
										<span>
											<Localize
												text={
													'onlinePayment.how.cureVoucher.servicesNotCovered'
												}
											/>
										</span>
									)}
								</span>
							</div>
						)}
						textClassName={styles.precision}
						onChange={
							isLoading ? noop : () => setPaymentMethod(PAYMENT_METHOD.USE_CURE)
						}
					/>
				)}
			</div>
		</div>
	);
};
