import React from 'react';
import { useTranslation } from '@planity/localization';
import { centsToPrice } from '@planity/helpers';
import useStyles from 'isomorphic-style-loader/useStyles';
import { CureItem } from './cure/cure_item';
import styles from './cures_list.module.scss';

export function CuresList({ userCures, linkToBusiness }) {
	const { t } = useTranslation();
	useStyles(styles);

	return (
		<div className={styles.cures}>
			<span className={styles.title}>{t('myAccount.myCures')}</span>
			{userCures.map(
				({
					id,
					boughtAt,
					expires,
					business,
					cureName,
					expirationDate,
					price,
					quantity,
					originalQuantity
				}) => (
					<div className={styles.cureContainer} id={'cures-list'} key={id}>
						<CureItem
							boughtAt={boughtAt}
							business={business}
							cureName={cureName}
							expires={expires}
							linkToBusiness={linkToBusiness}
							originalQuantity={originalQuantity}
							price={centsToPrice(price)}
							quantity={quantity}
						/>
					</div>
				)
			)}
		</div>
	);
}
